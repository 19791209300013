import './index.css';

class ProgressBar {

  constructor() {
    document.body.insertAdjacentHTML('beforeend',
      '<div id="preloader" class="preloader">\n' +
      '  <div class="preloaderContainer">\n' +
      '    <div class="preloaderLogoBase preloaderLogo"></div>\n' +
      '    <div id="percents">0%</div>' +
      '    <div class="progressContainer">\n' +
      '      <div class="progressBg">\n' +
      '        <div id="progressBar" class="progress"></div>\n' +
      '      </div>\n' +
      '    </div>\n' +
      '  </div>\n' +
      '</div>');

    this.preloaderElement = document.getElementById('preloader');
    this.progressBar = document.getElementById('progressBar');
    // setTimeout(() => {
    //   // this.startProgressLabel();
    //   // document.getElementById('progressBar').classList.add('animation')
    // }, 1000);

    window.OPPreloader = this;
  }

  setSecondLogo(src) {
    this.preloaderElement.innerHTML =
      '  <div class="preloaderContainer preloaderContainer__forTwoLogo">\n' +
      '    <div id="preloaderSecondLogo"  class="preloaderLogoBase preloaderSecondLogo"></div>\n' +
      '    <div id="percents">0%</div>' +
      '    <div class="progressContainer progressContainer__forTwoLogo">\n' +
      '      <div class="progressBg">\n' +
      '        <div id="progressBar" class="progress"></div>\n' +
      '      </div>\n' +
      '    </div>\n' +
      '    <div class="preloaderLogoBase preloaderLogoProweredBy"></div>\n' +
      '  </div>\n';

    this.progressBar = document.getElementById('progressBar');
    this.secondLogo = document.getElementById('preloaderSecondLogo');
    this.secondLogo.style.background = ` center / contain url(${src}) no-repeat `;
  }

  hide({withFade} = {}) {
    let delay = 1000;

    console.log('withFade',withFade)
    if (withFade) {
      setTimeout(() => {
        this.preloaderElement.classList.add('fade');
        this.onHide();
      }, 500)
      delay += 500;
    }

    console.log('Try hide');
    setTimeout(() => {
        this.preloaderElement.classList.remove('fade');
        this.preloaderElement.classList.add('hide');
      },
      delay);
  }

  setProgress(value) {
    const percents = document.getElementById('percents');
    percents.innerText = `${value.toFixed()}%`;

    const maskWidth = 100 - value;
    this.progressBar.style.setProperty('clip-path', `inset(0 ${maskWidth}% 0 0)`);
    this.progressBar.style.setProperty('-webkit-clip-path', `inset(0 ${maskWidth}% 0 0)`);
  }

  startProgressLabel() {
    let value = 0;
    const intervalDuration = 50;
    const duration = 3000;
    const stepsCount = duration / intervalDuration;
    const step = 98 / stepsCount;
    const percents = document.getElementById('percents');
    const interval = setInterval(() => {
      if (value > 99) {
        clearInterval(interval);
        return;
      }

      percents.innerText = `${value.toFixed()}%`;
      value += step;
    }, intervalDuration)
  }
  onHide() {
    if (window.OPWrapperService &&
      window.OPWrapperService.__proto__.hasOwnProperty('showStartPopups') &&
      typeof window.OPWrapperService.showStartPopups === 'function') {
      window.OPWrapperService.showStartPopups();
    }
  }
}

export default new ProgressBar();
